<template>
  <div>
    <h2>Gerador de QRcode</h2>
    <hr />
    <b-tabs>
      <b-tab title="Comandas">
        <b-row class="my-3">
          <b-col cols="6" class="d-flex">
            <b-form-input
              v-model="num_i"
              type="number"
              placeholder="Número inicial"
            ></b-form-input>
            <b-form-input
              v-model="num_f"
              type="number"
              placeholder="Número inicial"
            ></b-form-input>
            <b-btn variant="info" @click="gerarCodes" :disabled="loading">
              <span v-if="loading"><b-spinner></b-spinner></span> Gerar</b-btn>
          </b-col>
          <b-col cols="6" class="text-right">
            <b-btn @click="imprimirComandas">Imprimir</b-btn>
            <b-btn @click="downloadAllQRCodes" class="ml-2" variant="success">Baixar</b-btn>
          </b-col>
        </b-row>
    
        <div
          class="d-flex flex-row flex-wrap"
          id="divComandas"
          style="width: 800px"
        >
          <div v-for="n in comandas" :key="n.comanda_id">
            <div class="d-flex flex-column">
              <vue-qrcode
                :value="n.obj"
                :color="{ dark: '#000000ff', light: '#ffffffff' }"
                type="image/png"
                :width="200"
                :version="10"
                :scale="3"
                :quality="1"
                :errorCorrectionLevel="'H'"
                :margin="4"
                :qrcfg="{ ec_level: 'H', margin: 4 }"
                :logo="require('@/assets/logo.png')"
                @change="(ev) => onDataUrlChange(n, ev)"
                @error="handleQrcodeError"
              />
              <img :src="n.dataUrl" alt="qrcode" />
              <h3>Comanda n: {{ n.comanda_id }}</h3>
              <!-- <small><small>{{ n.obj }}</small></small> -->
            </div>
          </div>
        </div>
      </b-tab>

      <b-tab title="Mesas"> </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import VueQrcode from "vue-qrcode";
import PrinterWindowHelper from "../../utils/PrinterWindowHelper";
import JSZip from "jszip";

export default {
  components: {
    VueQrcode,
  },
  props: {},
  mounted() {
    console.log("mounted", VueQrcode);
  },
  data() {
    return {
      dataUrl: null,
      comandas: [],
      num_i: 1,
      num_f: 500,
      loading: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    getDomainFromUrl(){
      return window.location.origin;
    },
    gerarCodes() {
      this.loading = true;
      this.$forceUpdate();
      this.comandas = [];
      for (let i = this.num_i; i <= this.num_f; i++) {
        this.comandas.push({
          comanda_id: i,
          dataUrl: null,
          obj: this.getDomainFromUrl() + "/open/comanda/" + i,
        });
      }
      this.loading = false;

    },
    err(er) {
      console.log("error", er);
    },
    onDataUrlChange(n, dataUrl) {
      console.log("callonDataUrlChange", n, dataUrl )
      n.dataUrl = dataUrl;
    },
    imprimirComandas() {
      PrinterWindowHelper(document.querySelector("#divComandas").innerHTML);
    },

    downloadAllQRCodes() {
      const zip = new JSZip();
      this.comandas.forEach((item) => {
        if (item.dataUrl) {
          const base64Data = item.dataUrl.split(",")[1];
          zip.file(`comanda_${item.comanda_id}.png`, base64Data, {
            base64: true,
          });
        }
      });
      zip.generateAsync({ type: "blob" }).then((content) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(content);
        link.download = "qrcodes.zip";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    handleQrcodeError(error) {
      console.error("QR Code Error:", error);
    },
  },
};
</script>

<style lang="scss" scoped></style>
